module.exports = [
    {
        default: true,
        locale: 'it',
        label: 'Italiano',
        routes: {
            '/': '/',
            '/products/a': '/products/a',
            '/products/b': '/products/b',
            '/products/c': '/products/c',
            '/products/a/a': '/products/a/a',
            '/products/a/an': '/products/a/an',
            '/products/a/br': '/products/a/br',
            '/products/a/ez': '/products/a/ez',
            '/products/a/spiroflex': '/products/a/spiroflex',
            '/products/a/tlb': '/products/a/tlb',
            '/products/b/fk': '/products/b/fk',
            '/products/b/fke': '/products/b/fke',
            '/products/b/fkr': '/products/b/fkr',
            '/products/b/fkt': '/products/b/fkt',
            '/products/c/cn': '/products/c/cn',
            '/about-us': '/about-us',
            '/certifications': '/certifications',
            '/contact': '/contact',
            '/page1': '/page1',
            '/subpage/page1': '/subpage/page1',
            '/subpage/subsubpage/page1': '/subpage/subsubpage/page1',
        },
    },
    {
        locale: 'es',
        label: 'Español',
        routes: {
            '/': '/',
            '/products/a': '/products/a',
            '/products/b': '/products/b',
            '/products/c': '/products/c',
            '/products/a/a': '/products/a/a',
            '/products/a/an': '/products/a/an',
            '/products/a/br': '/products/a/br',
            '/products/a/ez': '/products/a/ez',
            '/products/a/spiroflex': '/products/a/spiroflex',
            '/products/a/tlb': '/products/a/tlb',
            '/products/b/fk': '/products/b/fk',
            '/products/b/fke': '/products/b/fke',
            '/products/b/fkr': '/products/b/fkr',
            '/products/b/fkt': '/products/b/fkt',
            '/products/c/cn': '/products/c/cn',
            '/contact': '/contact',
            '/about-us': '/about-us',
            '/certifications': '/certifications',
            '/page1': '/stranka1',
            '/subpage/page1': '/podstranka/stranka1',
            '/subpage/subsubpage/page1': '/podstranka/podpodstranka/stranka1',
        },
    },
    {
        locale: 'en',
        label: 'English',
        routes: {
            '/': '/',
            '/products/a': '/products/a',
            '/products/b': '/products/b',
            '/products/c': '/products/c',
            '/products/a/a': '/products/a/a',
            '/products/a/an': '/products/a/an',
            '/products/a/br': '/products/a/br',
            '/products/a/ez': '/products/a/ez',
            '/products/a/spiroflex': '/products/a/spiroflex',
            '/products/a/tlb': '/products/a/tlb',
            '/products/b/fk': '/products/b/fk',
            '/products/b/fke': '/products/b/fke',
            '/products/b/fkr': '/products/b/fkr',
            '/products/b/fkt': '/products/b/fkt',
            '/products/c/cn': '/products/c/cn',
            '/about-us': '/about-us',
            '/certifications': '/certifications',
            '/contact': '/contact',
            '/page1': '/page1',
            '/subpage/page1': '/subpage/page1',
            '/subpage/subsubpage/page1': '/subpage/subsubpage/page1',
        },
    },
]
